import * as React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const validateDemoCreation = (values) => {
  const errors = {};
  const MESSAGE = 'This field is required';

  if (!values?.email) {
    errors.email = MESSAGE;
  }
  return errors;
};

export const NewsletterList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="email" />
      <TextField source="pageURL" label="Page URL" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const NewsletterCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <TextInput source="email" label="Email" isRequired />
    </SimpleForm>
  </Create>
);
export const NewsletterEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <TextInput source="email" label="Email" isRequired />
    </SimpleForm>
  </Edit>
);
