import * as React from 'react';
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { LEAD_STATUS } from './common/constant';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="email" alwaysOn />
  </Filter>
);

const validateDemoCreation = (values) => {
  const errors = {};
  const MESSAGE = 'This field is required';

  if (!values?.email) {
    errors.email = MESSAGE;
  }
  return errors;
};

export const ContactUsList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="first_name" label="First Name" />
      <TextField source="last_name" label="Last Name" />
      <TextField source="email" label="Email" />
      <TextField source="phone_code" label="Phone Code" />
      <TextField source="phone" label="Phone" />
      <TextField source="subject" label="Subject" />
      <TextField source="message" label="Message" />
      <TextField source="status" label="Status" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const ContactUsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <SelectInput
        resettable
        source="status"
        label="Lead Status"
        defaultValue="Open"
        choices={LEAD_STATUS}
        isRequired
      />
    </SimpleForm>
  </Edit>
);
