// LoginPage.js
import React from 'react';
import { Login, LoginForm } from 'react-admin';

const CustomLoginForm = (props) => (
  <LoginForm {...props}>
    <h1>test</h1>
  </LoginForm>
);

const CustomLoginPage = (props) => (
  <Login loginForm={<CustomLoginForm />} {...props} className="login-form-bg" />
);

export default CustomLoginPage;
