import * as React from 'react';
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const validateDemoCreation = (values) => {
  const errors = {};
  const MESSAGE = 'This field is required';

  if (!values?.name) {
    errors.name = MESSAGE;
  }
  return errors;
};

export const DiamondPurityList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const DiamondPurityCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <TextInput source="name" label="Name" isRequired />
    </SimpleForm>
  </Create>
);
export const DiamondPurityEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <TextInput source="name" label="Name" isRequired />
    </SimpleForm>
  </Edit>
);
