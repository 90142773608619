import { collection, onSnapshot, query } from 'firebase/firestore';
import React, { createContext, useEffect, useReducer } from 'react';
import { db } from '../firebase/config';

const initialState = {};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_DIAMOND_TYPE_LIST':
      return { ...state, DIAMOND_TYPE_LIST: action?.data };
    case 'SET_SETTING_TYPE_LIST':
      return { ...state, SETTING_TYPE_LIST: action?.data };
    case 'SET_DIAMOND_COLOR_LIST':
      return { ...state, DIAMOND_COLOR_LIST: action?.data };
    case 'SET_DIAMOND_CLARITY':
      return { ...state, DIAMOND_CLARITY: action?.data };
    case 'SET_PURITY_LIST':
      return { ...state, PURITY_LIST: action?.data };
    case 'SET_SUB_CATEGORY_LIST':
      return { ...state, SUB_CATEGORY_LIST: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  useEffect(() => {
    const q1 = query(collection(db, 'diamond type'));
    onSnapshot(q1, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({ id: doc?.data()?.name, name: doc?.data()?.name });
      });
      dispatch({ type: 'SET_DIAMOND_TYPE_LIST', data: type });
      // console.log('Current cities in CA: ', type);
    });
    const q2 = query(collection(db, 'setting type'));
    onSnapshot(q2, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({ id: doc?.data()?.name, name: doc?.data()?.name });
      });
      dispatch({ type: 'SET_SETTING_TYPE_LIST', data: type });
    });
    const q3 = query(collection(db, 'diamond color'));
    onSnapshot(q3, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({ id: doc?.data()?.name, name: doc?.data()?.name });
      });
      dispatch({ type: 'SET_DIAMOND_COLOR_LIST', data: type });
    });

    const q4 = query(collection(db, 'diamond clarity'));
    onSnapshot(q4, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({ id: doc?.data()?.name, name: doc?.data()?.name });
      });
      dispatch({ type: 'SET_DIAMOND_CLARITY', data: type });
    });
    const q5 = query(collection(db, 'diamond purity'));
    onSnapshot(q5, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({ id: doc?.data()?.name, name: doc?.data()?.name });
      });
      dispatch({ type: 'SET_PURITY_LIST', data: type });
    });
    const q6 = query(collection(db, 'sub category'));
    onSnapshot(q6, (querySnapshot) => {
      const type = [];
      querySnapshot.forEach((doc) => {
        type.push({
          id: doc?.data()?.name,
          name: doc?.data()?.name,
          mainCategory: doc?.data()?.mainCategory,
        });
      });
      dispatch({ type: 'SET_SUB_CATEGORY_LIST', data: type });
    });
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext?.Consumer;

export { AppContext, AppContextConsumer, AppContextProvider };
