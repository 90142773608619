import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppContextProvider } from './common/AppContext';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
