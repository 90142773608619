import RichTextInput from 'ra-input-rich-text';
import * as React from 'react';
import { useContext } from 'react';

import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  CloneButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { AppContext } from './common/AppContext';
import {
  DIAMOND_TYPE_LIST,
  GENDER_LIST,
  LABEL_LIST,
  PRODUCT_COLOR,
  UNIT_LIST,
} from './common/constant';

const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const postFilters = [
  <TextInput label="Search" source="title" alwaysOn />,
  <TextInput label="Style No" source="style_number" />,
];

const validateDemoCreation = (values) => {
  const errors = {};
  const MESSAGE = 'This field is required';

  if (!values?.title) {
    errors.title = MESSAGE;
  }
  if (!values?.category) {
    errors.category = MESSAGE;
  }
  // if (!values?.short_description) {
  //   errors.short_description = MESSAGE;
  // }
  if (!values?.style_number) {
    errors.style_number = MESSAGE;
  }
  if (!values?.gender) {
    errors.gender = MESSAGE;
  }
  // if (!values?.setting_type) {
  //   errors.setting_type = MESSAGE;
  // }
  return errors;
};

export const PendantList = (props) => (
  <List {...props} filters={postFilters}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="category" />
      <TextField source="labels" />
      <TextField source="style_number" />
      <TextField source="gender" />
      <BooleanField label="Published" source="is_active" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
      <CloneButton />
    </Datagrid>
  </List>
);

export const PendantShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <h3>Product Details</h3>
      <BooleanField label="Published" source="is_active" />
      <TextField source="title" />
      <TextField source="category" />
      <TextField source="sub_category" />
      <TextField source="type" />
      <TextField source="labels" />
      <TextField source="style_number" />
      <TextField source="gender" />
      <TextField source="keywords" />
      <ImageField
        source="thumbnail_image.src"
        label="Thumbnail Image"
        title="title"
      />
      <h5>Product Filters</h5>
      <BooleanField label="is_total_diamond" source="is_total_diamond" />
      <BooleanField label="is_center_diamond" source="is_center_diamond" />

      <h3>Variant Details</h3>
      <ArrayField source="items" label="Variants">
        <Datagrid bulkActionButtons={false}>
          <TextField source="sku" label="SKU" />
          <TextField source="color" />
          <TextField source="size" />
          <TextField source="length" />
          <TextField source="purity" />
          <TextField source="original_price" />
          <TextField source="sale_price" />
          <TextField source="quantity" label="Available Qty." />
        </Datagrid>
      </ArrayField>

      <h3>SEO Details</h3>
      <TextField source="meta_title" label="Meta Title" />
      <TextField source="meta_description" label="Meta Description" />
    </SimpleShowLayout>
  </Show>
);

export const PendantCreate = (props) => {
  const { state } = useContext(AppContext);
  return (
    <Create {...props}>
      <SimpleForm validate={validateDemoCreation}>
        <div className="row">
          <div className="col-2">
            <BooleanInput
              source="is_active"
              label="Published"
              defaultValue={true}
            />
          </div>
          <div className="col-6">
            <TextInput source="title" label="Title" isRequired />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="category"
              label="Category"
              defaultValue="Pendants"
              choices={[{ id: 'Pendants', name: 'Pendants' }]}
              isRequired
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="sub_category"
              label="Sub category"
              choices={
                state?.SUB_CATEGORY_LIST?.filter(
                  ({ mainCategory }) => mainCategory === 'Pendants',
                ) || []
              }
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="type"
              label="Type"
              defaultValue="Lab Grown"
              choices={state?.DIAMOND_TYPE_LIST}
              isRequired
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="labels"
              label="Labels"
              choices={LABEL_LIST}
            />
          </div>
          <div className="col-4">
            <TextInput
              source="keywords"
              label="Keywords"
              helperText="Ex- Best Pendants, Popular Pendants etc."
            />
          </div>
          <div className="col-4">
            <TextInput source="productType" label="Product Type" />
          </div>
          <div className="col-4">
            <TextInput source="style_number" label="Style Number" isRequired />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="gender"
              label="Gender"
              choices={GENDER_LIST}
              isRequired
            />
          </div>
          <div className="col-4">
            <ImageInput
              source="thumbnail_image"
              accept={allowedTypes}
              label="Thumbnail Image"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
          <div className="col-4"></div>
          <div className="col-12">Filters:</div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond"
              label="Total Diamond CW."
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond_color"
              label="Total Diamond color"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond_clarity"
              label="Total Diamond clarity"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond"
              label="Center Diamond CW."
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond_color"
              label="Center Diamond color"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond_clarity"
              label="Center Diamond clarity"
              defaultValue={false}
            />
          </div>
        </div>

        <h3>Variant Details</h3>

        <ArrayInput source="items">
          <SimpleFormIterator className="product-details-wrapper">
            <TextInput source="sku" helperText={false} label="SKU" />
            <SelectInput
              resettable
              source="color"
              label="Color"
              choices={PRODUCT_COLOR}
            />
            <NumberInput source="size" helperText={false} label="Size" />

            <TextInput source="length" helperText={false} label="Length" />
            <SelectInput
              resettable
              source="purity"
              label="Purity"
              choices={state?.PURITY_LIST}
            />
            <NumberInput
              source="original_price"
              helperText={false}
              label="Original Price"
            />
            <NumberInput
              source="sale_price"
              helperText={false}
              label="Sale Price"
            />
            <NumberInput
              source="quantity"
              helperText={false}
              label="Available Qty."
            />
            <TextInput source="lab_name" label="LAB Name" />
            <TextInput source="short_description" label="Description" />
            <TextInput source="height" label="Product Height" />
            <TextInput source="width" label="Product Width" />
            <TextInput source="productLength" label="Product Length" />
            <TextInput source="weight" label="Product Weight" />

            <SelectInput
              resettable
              source="setting_type"
              label="Setting Type"
              choices={state?.SETTING_TYPE_LIST}
            />
            <SelectInput
              resettable
              source="unit"
              label="Unit"
              choices={UNIT_LIST}
              defaultValue="ct"
            />
            <NumberInput
              source="total_diamond_count"
              helperText={false}
              label="Total Diamond Count"
            />
            <TextInput
              source="total_diamond_weight"
              helperText={false}
              label="Total Diamond Carat Weight"
            />
            <SelectInput
              resettable
              source="diamond_type"
              label="Diamond Type"
              choices={DIAMOND_TYPE_LIST}
            />

            <SelectInput
              resettable
              source="diamond_color"
              label="Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="diamond_clarity"
              label="Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <NumberInput
              source="center_diamond_count"
              helperText={false}
              label="Center Diamond Count"
            />
            <TextInput
              source="center_diamond_weight"
              helperText={false}
              label="Center Diamond Carat Weight"
            />
            <SelectInput
              resettable
              source="center_diamond_color"
              label="Center Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="center_diamond_clarity"
              label="Center Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <TextInput
              source="side_diamond_weight"
              helperText={false}
              label="side diamond carat weight"
            />
            <NumberInput
              source="side_diamond_count"
              helperText={false}
              label="Side Diamond Count"
            />
            <SelectInput
              resettable
              source="side_diamond_color"
              label="Side Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="side_diamond_clarity"
              label="Side Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <ImageInput
              source="product_images"
              accept={allowedTypes}
              label="Product Images"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>

        <h3>SEO Details</h3>
        <div className="row">
          <div className="col-6">
            <TextInput source="meta_title" label="Meta Title" />
          </div>
          <div className="col-6">
            <TextInput source="meta_description" label="Meta Description" />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const PendantEdit = (props) => {
  const { state } = useContext(AppContext);
  return (
    <Edit {...props}>
      <SimpleForm validate={validateDemoCreation}>
        <div className="row">
          <div className="col-2">
            <BooleanInput
              source="is_active"
              label="Published"
              defaultValue={true}
            />
          </div>
          <div className="col-6">
            <TextInput source="title" label="Title" isRequired />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="category"
              label="Category"
              defaultValue="Pendants"
              choices={[{ id: 'Pendants', name: 'Pendants' }]}
              isRequired
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="sub_category"
              label="Sub category"
              choices={
                state?.SUB_CATEGORY_LIST?.filter(
                  ({ mainCategory }) => mainCategory === 'Pendants',
                ) || []
              }
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="type"
              label="Type"
              defaultValue="Lab Grown"
              choices={state?.DIAMOND_TYPE_LIST}
              isRequired
            />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="labels"
              label="Labels"
              choices={LABEL_LIST}
            />
          </div>
          <div className="col-4">
            <TextInput source="style_number" label="Style Number" isRequired />
          </div>
          <div className="col-4">
            <SelectInput
              resettable
              source="gender"
              label="Gender"
              choices={GENDER_LIST}
              isRequired
            />
          </div>

          <div className="col-4">
            <TextInput
              source="keywords"
              label="Keywords"
              helperText="Ex- Best Rings, Popular Rings etc."
            />
          </div>
          <div className="col-4">
            <TextInput source="productType" label="Product Type" />
          </div>
          <div className="col-4">
            <ImageInput
              source="thumbnail_image"
              accept={allowedTypes}
              label="Thumbnail Image"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
          <div className="col-4"></div>
          <div className="col-12">Filters:</div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond"
              label="Total Diamond CW."
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond_color"
              label="Total Diamond color"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_total_diamond_clarity"
              label="Total Diamond clarity"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond"
              label="Center Diamond CW."
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond_color"
              label="Center Diamond color"
              defaultValue={false}
            />
          </div>
          <div className="col-4">
            <BooleanInput
              source="is_center_diamond_clarity"
              label="Center Diamond clarity"
              defaultValue={false}
            />
          </div>
        </div>
        <h3>Variant Details</h3>
        <ArrayInput source="items">
          <SimpleFormIterator className="product-details-wrapper">
            <TextInput source="sku" helperText={false} label="SKU" />
            <SelectInput
              resettable
              source="color"
              label="Color"
              choices={PRODUCT_COLOR}
            />
            <NumberInput source="size" helperText={false} label="Size" />
            <TextInput source="length" helperText={false} label="Length" />
            <SelectInput
              resettable
              source="purity"
              label="Purity"
              choices={state?.PURITY_LIST}
            />
            <NumberInput
              source="original_price"
              helperText={false}
              label="Original Price"
            />
            <NumberInput
              source="sale_price"
              helperText={false}
              label="Sale Price"
            />
            <NumberInput
              source="quantity"
              helperText={false}
              label="Available Qty."
            />
            <RichTextInput source="short_description" label="Description" />
            <TextInput source="lab_name" label="LAB Name" />
            <TextInput source="height" label="Product Height" />
            <TextInput source="width" label="Product Width" />
            <TextInput source="productLength" label="Product Length" />
            <TextInput source="weight" label="Product Weight" />

            <SelectInput
              resettable
              source="setting_type"
              label="Setting Type"
              choices={state?.SETTING_TYPE_LIST}
              isRequired
            />
            <SelectInput
              resettable
              source="unit"
              label="Unit"
              choices={UNIT_LIST}
              defaultValue="ct"
            />
            <NumberInput
              source="total_diamond_count"
              helperText={false}
              label="Total Diamond Count"
            />
            <TextInput
              source="total_diamond_weight"
              helperText={false}
              label="Total Diamond Carat Weight"
            />
            <SelectInput
              resettable
              source="diamond_type"
              label="Diamond Type"
              choices={DIAMOND_TYPE_LIST}
            />

            <SelectInput
              resettable
              source="diamond_color"
              label="Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="diamond_clarity"
              label="Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <NumberInput
              source="center_diamond_count"
              helperText={false}
              label="Center Diamond Count"
            />
            <TextInput
              source="center_diamond_weight"
              helperText={false}
              label="Center Diamond Carat Weight"
            />
            <SelectInput
              resettable
              source="center_diamond_color"
              label="Center Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="center_diamond_clarity"
              label="Center Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <TextInput
              source="side_diamond_weight"
              helperText={false}
              label="side diamond carat weight"
            />
            <NumberInput
              source="side_diamond_count"
              helperText={false}
              label="Side Diamond Count"
            />
            <SelectInput
              resettable
              source="side_diamond_color"
              label="Side Diamond Color"
              choices={state?.DIAMOND_COLOR_LIST}
            />
            <SelectInput
              resettable
              source="side_diamond_clarity"
              label="Side Diamond Clarity"
              choices={state?.DIAMOND_CLARITY}
            />
            <ImageInput
              source="product_images"
              accept={allowedTypes}
              label="Product Images"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>

        <h3>SEO Details</h3>
        <div className="row">
          <div className="col-6">
            <TextInput source="meta_title" label="Meta Title" />
          </div>
          <div className="col-6">
            <TextInput source="meta_description" label="Meta Description" />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
