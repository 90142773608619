import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import './App.css';
import CustomLoginPage from './CustomLoginPage';

import {
  AccessoriesCreate,
  AccessoriesEdit,
  AccessoriesList,
  AccessoriesShow,
} from './accessories';
import {
  BannerIcon,
  ClarityIcon,
  ColorIcon,
  ImageIcon,
  LeadIcon,
  ListIcon,
  OrderIcon,
  ProductIcon,
  UserIcon,
} from './assets/svgs';
import { BannerCreate, BannerEdit, BannerList } from './banners';
import {
  BraceletCreate,
  BraceletEdit,
  BraceletList,
  BraceletShow,
} from './bracelet';
import {
  CategoryImageCreate,
  CategoryImageEdit,
  CategoryImageList,
} from './category-images';
import { firebaseConfig as config } from './config';
import { ContactUsEdit, ContactUsList } from './contact-us';
import {
  EarringCreate,
  EarringEdit,
  EarringList,
  EarringShow,
} from './earring';
import {
  NecklaceCreate,
  NecklaceEdit,
  NecklaceList,
  NecklaceShow,
} from './necklace';
import {
  NewsletterCreate,
  NewsletterEdit,
  NewsletterList,
} from './newsletters';
import { OrdersEdit, OrdersList } from './orders';
import {
  PendantCreate,
  PendantEdit,
  PendantList,
  PendantShow,
} from './pendant';
import { RingCreate, RingEdit, RingList, RingShow } from './rings';
import {
  DiamondClarityCreate,
  DiamondClarityList,
} from './settings/diamondClarity';
import { DiamondColorCreate, DiamondColorList } from './settings/diamondColor';
import {
  DiamondPurityCreate,
  DiamondPurityList,
} from './settings/diamondPurity';
import { DiamondTypeCreate, DiamondTypeList } from './settings/diamondTypes';
import { SettingTypeCreate, SettingTypeList } from './settings/settingTypes';
import { SubCategoryCreate, SubCategoryList } from './settings/subCategories';
import { UsersList } from './users';

const options = {
  logging: true,
  rootRef: '/',
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="users"
          list={UsersList}
          // edit={UsersEdit}
          icon={UserIcon}
        />
        <Resource
          name="orders"
          list={OrdersList}
          edit={OrdersEdit}
          icon={OrderIcon}
        />
        <Resource
          name="banners"
          create={BannerCreate}
          list={BannerList}
          edit={BannerEdit}
          icon={BannerIcon}
        />
        <Resource
          name="category-images"
          create={CategoryImageCreate}
          list={CategoryImageList}
          edit={CategoryImageEdit}
          icon={ImageIcon}
          options={{ label: 'Category Images' }}
        />
        <Resource
          name="rings"
          create={RingCreate}
          list={RingList}
          edit={RingEdit}
          icon={ProductIcon}
          show={RingShow}
        />
        <Resource
          name="earrings"
          create={EarringCreate}
          list={EarringList}
          edit={EarringEdit}
          icon={ProductIcon}
          show={EarringShow}
        />
        <Resource
          name="pendants"
          create={PendantCreate}
          list={PendantList}
          edit={PendantEdit}
          icon={ProductIcon}
          show={PendantShow}
        />
        <Resource
          name="necklaces"
          create={NecklaceCreate}
          list={NecklaceList}
          edit={NecklaceEdit}
          icon={ProductIcon}
          show={NecklaceShow}
        />
        <Resource
          name="bracelets"
          create={BraceletCreate}
          list={BraceletList}
          edit={BraceletEdit}
          icon={ProductIcon}
          show={BraceletShow}
        />
        <Resource
          name="accessories"
          create={AccessoriesCreate}
          list={AccessoriesList}
          edit={AccessoriesEdit}
          icon={ProductIcon}
          show={AccessoriesShow}
        />
        <Resource
          name="newsletters"
          create={NewsletterCreate}
          list={NewsletterList}
          edit={NewsletterEdit}
          icon={ProductIcon}
        />
        <Resource
          name="leads"
          list={ContactUsList}
          edit={ContactUsEdit}
          icon={LeadIcon}
        />
        <Resource
          name="diamond type"
          create={DiamondTypeCreate}
          list={DiamondTypeList}
          icon={ListIcon}
          options={{ label: 'Diamond Types' }}
        />
        <Resource
          name="setting type"
          create={SettingTypeCreate}
          list={SettingTypeList}
          icon={ListIcon}
          options={{ label: 'Setting Types' }}
        />
        <Resource
          name="diamond color"
          create={DiamondColorCreate}
          list={DiamondColorList}
          icon={ColorIcon}
          options={{ label: 'Colors' }}
        />
        <Resource
          name="diamond clarity"
          create={DiamondClarityCreate}
          list={DiamondClarityList}
          icon={ClarityIcon}
          options={{ label: 'Clarity' }}
        />
        <Resource
          name="diamond purity"
          create={DiamondPurityCreate}
          list={DiamondPurityList}
          icon={ClarityIcon}
          options={{ label: 'Purity' }}
        />
        <Resource
          name="sub category"
          create={SubCategoryCreate}
          list={SubCategoryList}
          icon={ListIcon}
          options={{ label: 'Sub Category' }}
        />
      </Admin>
    );
  }
}

export default App;
