const DIAMOND_COLOR_LIST = [
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'F', name: 'F' },
  { id: 'G', name: 'G' },
  { id: 'H', name: 'H' },
];

const DIAMOND_CLARITY = [
  { id: '0.5CT', name: '0.5CT' },
  { id: '1CT', name: '1CT' },
  { id: '1.50CT', name: '1.50CT' },
  { id: '2CT', name: '2CT' },
];

const CENTER_DIAMOND_CLARITY = [
  { id: 'VVS', name: 'VVS' },
  { id: 'VS', name: 'VS' },
  { id: 'SI', name: 'SI' },
];

const GENDER_LIST = [
  { id: 'Male', name: 'Male' },
  { id: 'Female', name: 'Female' },
  { id: 'Unisex', name: 'Unisex' },
];

const PURITY_LIST = [
  { id: '10K', name: '10K' },
  { id: '12K', name: '12K' },
  { id: '14K', name: '14K' },
  { id: '18K', name: '18K' },
];

const PRODUCT_COLOR = [
  { id: 'Pink Gold', name: 'Pink Gold' },
  { id: 'White Gold', name: 'White Gold' },
  { id: 'Yellow Gold', name: 'Yellow Gold' },
];

const LABEL_LIST = [
  { id: 'New Arrivals', name: 'New Arrivals' },
  { id: 'Trending', name: 'Trending' },
  { id: 'Bestseller', name: 'Bestseller' },
  { id: 'Featured', name: 'Featured' },
];

const PRODUCT_LIST = [
  { id: 'PRODUCT 1', name: 'PRODUCT 1' },
  { id: 'PRODUCT 2', name: 'PRODUCT 2' },
];

const DIAMOND_TYPE_LIST = [{ id: 'Lab Grown', name: 'Lab Grown' }];
const SETTING_TYPE_LIST = [
  { id: 'Prong', name: 'Prong' },
  { id: 'Bezel', name: 'Bezel' },
  { id: 'Pave', name: 'Pave' },
  { id: 'Channel', name: 'Channel' },
];
const UNIT_LIST = [
  { id: 'ct', name: 'ct' },
  { id: 'cm', name: 'cm' },
  { id: 'inch', name: 'inch' },
  { id: 'meter', name: 'meter' },
  { id: 'gm', name: 'gm' },
];
const ALL_CATEGORY_LIST = [
  { id: 'Accessories', name: 'Accessories' },
  { id: 'Bracelets', name: 'Bracelets' },
  { id: 'Earrings', name: 'Earrings' },
  { id: 'Necklaces', name: 'Necklaces' },
  { id: 'Pendants', name: 'Pendants' },
  { id: 'Rings', name: 'Rings' },
];
const LEAD_STATUS = [
  { id: 'Open', name: 'Open' },
  { id: 'Contacted', name: 'Contacted' },
  { id: 'Qualified', name: 'Qualified' },
  { id: 'Unqualified', name: 'Unqualified' },
];
const PAYMENT_TYPE = [
  { id: 'PAID', name: 'PAID' },
  { id: 'REFUNDED', name: 'REFUNDED' },
  { id: 'UNPAID', name: 'UNPAID' },
];

const ORDER_TYPE = [
  { id: 'DELIVERED', name: 'DELIVERED' },
  { id: 'PLACED', name: 'PLACED' },
  { id: 'SHIPPED', name: 'SHIPPED' },
  { id: 'CANCELLED', name: 'CANCELLED' },
];
export {
  ALL_CATEGORY_LIST,
  CENTER_DIAMOND_CLARITY,
  DIAMOND_CLARITY,
  DIAMOND_COLOR_LIST,
  DIAMOND_TYPE_LIST,
  GENDER_LIST,
  LABEL_LIST,
  LEAD_STATUS,
  ORDER_TYPE,
  PAYMENT_TYPE,
  PRODUCT_COLOR,
  PRODUCT_LIST,
  PURITY_LIST,
  SETTING_TYPE_LIST,
  UNIT_LIST,
};
