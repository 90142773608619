import * as React from 'react';
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { ORDER_TYPE, PAYMENT_TYPE } from './common/constant';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="customer_email" alwaysOn />
  </Filter>
);

export const OrdersList = (props) => {
  return (
    <>
      <List {...props} filters={<PostFilter />}>
        <Datagrid>
          <TextField source="customer_email" label="Email" />
          <TextField source="order_date" label="Order Date" />
          <TextField source="order_status" label="Order Status" />
          <TextField source="payment_status" label="Payment Status" />
          <TextField source="payment_intent" label="Payment ID" />
          <EditButton label="" redirect={false} />
          <DeleteButton label="" redirect={false} />
        </Datagrid>
      </List>
    </>
  );
};

export const OrdersEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          resettable
          source="payment_status"
          label="Payment Status"
          choices={PAYMENT_TYPE}
          isRequired
        />
        <SelectInput
          resettable
          source="order_status"
          label="Order Status"
          choices={ORDER_TYPE}
          isRequired
        />
        <TextInput label="Tracking Link" source="tracking_link" />
      </SimpleForm>
    </Edit>
  );
};
