import * as React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import { ALL_CATEGORY_LIST } from './common/constant';

const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

const validateDemoCreation = (values) => {
  const errors = {};
  const MESSAGE = 'This field is required';

  if (!values?.title) {
    errors.title = MESSAGE;
  }
  if (!values?.button_text) {
    errors.button_text = MESSAGE;
  }
  if (!values?.image) {
    errors.image = MESSAGE;
  }
  return errors;
};

export const CategoryImageList = (props) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="category" />
      <TextField source="button_text" />
      <BooleanField label="Published" source="is_active" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

export const CategoryImageCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <BooleanInput source="is_active" label="Published" defaultValue={true} />
      <TextInput source="title" label="Title" isRequired />
      <TextInput source="button_text" label="Button Text" isRequired />
      <SelectInput
        resettable
        source="category"
        label="Category"
        defaultValue="Ring"
        choices={ALL_CATEGORY_LIST}
        isRequired
      />
      <ImageInput source="image" accept={allowedTypes} label="Banner Image">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
export const CategoryImageEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={validateDemoCreation}>
      <BooleanInput source="is_active" label="Published" defaultValue={true} />
      <TextInput source="title" label="Title" isRequired />
      <TextInput source="button_text" label="Button Text" isRequired />
      <SelectInput
        resettable
        source="category"
        label="Category"
        defaultValue="Ring"
        choices={ALL_CATEGORY_LIST}
        isRequired
      />
      <ImageInput source="image" accept={allowedTypes} label="Banner Image">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
