import React from 'react';
const UserIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <circle cx="12" cy="6" r="4" fill="#000000"></circle>{' '}
      <path
        d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
        fill="#000000"
      ></path>{' '}
    </g>
  </svg>
);
const ProductIcon = () => (
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <rect x="19" y="18.92" width="60" height="16" rx="4" ry="4"></rect>
      <rect x="19" y="40.92" width="27" height="16" rx="4" ry="4"></rect>
      <rect x="19" y="62.92" width="27" height="16" rx="4" ry="4"></rect>
      <rect x="52" y="40.92" width="27" height="16" rx="4" ry="4"></rect>
      <rect x="52" y="62.92" width="27" height="16" rx="4" ry="4"></rect>
    </g>
  </svg>
);
const BannerIcon = () => (
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <rect x="19" y="18.92" width="60" height="16" rx="4" ry="4"></rect>
      <rect x="19" y="40.92" width="27" height="16" rx="4" ry="4"></rect>
      <rect x="19" y="62.92" width="27" height="16" rx="4" ry="4"></rect>
      <path d="M64.95,72.49a1.45,1.45,0,0,0,2.1,0l11.5-11.4a1.45,1.45,0,0,0,0-2.1L67,47.49a1.45,1.45,0,0,0-2.1,0l-2.1,2.1a1.45,1.45,0,0,0,0,2.1l3.6,3.6a1,1,0,0,1-.7,1.7H53.6a1.63,1.63,0,0,0-1.6,1.5v3A1.71,1.71,0,0,0,53.6,63H65.75a1,1,0,0,1,.7,1.7l-3.6,3.6a1.45,1.45,0,0,0,0,2.1Z"></path>
    </g>
  </svg>
);
const LeadIcon = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path d="M37.1,62.3H77c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H37.1c-1.1,0-2-0.9-2-2v-4C35.1,63.2,36,62.3,37.1,62.3z"></path>{' '}
      <path d="M25,70.3h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4c0.1,1-0.7,1.9-1.8,2 C25.1,70.3,25,70.3,25,70.3z"></path>{' '}
      <path d="M25,52.4h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4c0.1,1-0.7,1.9-1.8,2 C25.1,52.4,25,52.4,25,52.4z"></path>{' '}
      <path d="M25,34.5h-4c-1.1,0-2-0.9-2-2v-4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2v4c0.1,1-0.7,1.9-1.8,2 C25.1,34.5,25,34.5,25,34.5z"></path>{' '}
      <path d="M37.1,44.4h35.1c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H37.1c-1.1,0-2-0.9-2-2v-4C35.1,45.3,36,44.4,37.1,44.4z"></path>{' '}
      <path d="M37.1,26.6H77c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H37.1c-1.1,0-2-0.9-2-2v-4C35.1,27.5,36,26.6,37.1,26.6z"></path>{' '}
    </g>
  </svg>
);

const OrderIcon = () => (
  <svg
    fill="#000000"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M20.276,2.553,22,6H2L3.724,2.553A1,1,0,0,1,4.618,2H19.382A1,1,0,0,1,20.276,2.553ZM2,8H22V21a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1Zm6.293,7.206a1,1,0,0,0,1.414,0L11,13.913V18.5a1,1,0,0,0,2,0V13.913l1.293,1.293a1,1,0,0,0,1.414-1.414l-3-3a1,1,0,0,0-1.414,0l-3,3A1,1,0,0,0,8.293,15.206Z"></path>
    </g>
  </svg>
);

const ListIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        d="M6 12H18M3 6H21M9 18H15"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>{' '}
    </g>
  </svg>
);

const ColorIcon = () => (
  <svg
    fill="#000000"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 29.73 29.73"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <g>
        {' '}
        <path d="M14.865,0C6.655,0,0,6.655,0,14.865c0,1.714,0.201,2.83,0.767,4.546c1.104,3.188,6.896-2.808,9.388,0.729 c2.492,3.535-5.62,6.64-0.18,8.764c2.475,0.601,3.175,0.826,4.89,0.826c8.21,0,14.865-6.654,14.865-14.864 C29.73,6.655,23.075,0,14.865,0z M22.077,4.955c1.694,0,3.069,1.17,3.069,2.614c0,1.442-1.375,2.613-3.069,2.613 c-1.695,0-3.07-1.171-3.07-2.613C19.007,6.125,20.381,4.955,22.077,4.955z M4.74,15.802c-1.695,0-3.069-1.171-3.069-2.614 s1.375-2.614,3.069-2.614c1.696,0,3.071,1.171,3.071,2.614S6.437,15.802,4.74,15.802z M8.335,9.784c-1.695,0-3.07-1.17-3.07-2.614 c0-1.444,1.375-2.614,3.07-2.614s3.07,1.17,3.07,2.614C11.405,8.614,10.03,9.784,8.335,9.784z M12.078,4.189 c0-1.443,1.374-2.615,3.07-2.615c1.694,0,3.068,1.172,3.068,2.615s-1.375,2.614-3.068,2.614 C13.452,6.803,12.078,5.632,12.078,4.189z M17.341,27.627c-1.696,0-3.069-1.17-3.069-2.613s1.375-2.613,3.069-2.613 c1.695,0,3.07,1.17,3.07,2.613S19.036,27.627,17.341,27.627z M23.48,23.155c-1.695,0-3.069-1.173-3.069-2.614 c0-1.443,1.374-2.614,3.069-2.614c1.694,0,3.069,1.171,3.069,2.614C26.55,21.982,25.176,23.155,23.48,23.155z M25.146,16.604 c-1.695,0-3.07-1.17-3.07-2.614s1.375-2.614,3.07-2.614s3.07,1.17,3.07,2.614S26.843,16.604,25.146,16.604z"></path>{' '}
      </g>{' '}
    </g>
  </svg>
);

const ClarityIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 -2 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path
        fill="#000000"
        fill-rule="evenodd"
        d="M205.446968,120.162298 C205.747612,120.624948 206.219232,120.620273 206.507616,120.139771 L215.077786,105.860229 C215.362922,105.385138 215.138765,105 214.602268,105 L196.585874,105 C196.038116,105 195.840828,105.379727 196.138434,105.837702 L205.446968,120.162298 Z M205.991331,119 L213.594071,106 L197.594071,106 L205.991331,119 Z M205.991331,117 L205.991331,107 L199.594071,107 L205.991331,117 Z"
        transform="translate(-196 -105)"
      ></path>{' '}
    </g>
  </svg>
);

const ImageIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
    class="bi bi-images"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {' '}
      <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>{' '}
      <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"></path>{' '}
    </g>
  </svg>
);
export {
  BannerIcon,
  ClarityIcon,
  ColorIcon,
  ImageIcon,
  LeadIcon,
  ListIcon,
  OrderIcon,
  ProductIcon,
  UserIcon,
};
