import * as React from 'react';
import {
  BooleanInput,
  Datagrid,
  DeleteButton,
  Edit,
  Filter,
  FunctionField,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>
);

export const UsersList = (props) => {
  return (
    <>
      <List {...props} filters={<PostFilter />}>
        <Datagrid>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <FunctionField
            label="Items in Cart"
            render={({ cart }) => cart?.length || 0}
          />
          <FunctionField
            label="Items in Wishlist"
            render={({ wishList }) => wishList?.length || 0}
          />
          {/* <FunctionField
            label="Total Order"
            render={({ orders }) => orders?.length || 0}
          /> */}
          {/* <EditButton label="" redirect={false} /> */}
          <DeleteButton label="" redirect={false} />
        </Datagrid>
      </List>
    </>
  );
};

export const UsersEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput label="Permission" source="isVerified" />
      </SimpleForm>
    </Edit>
  );
};
